import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/GetApp';
import {
  TablePagination, TableRow, IconButton, Hidden,
} from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import SearchForm from '../../components/SearchForm/SearchForm';
import DeaStatusBaneer from '../DeaStatusBanner/DeaStatusBanner';
import LicenseStatusBanner from '../LicenseStatusBanner/LicenseStatusBanner';
import removeEmptyKeys from '../../utils/removeEmptyKeys';
import storage from '../../services/storage';
import config from '../../config';

class TechBulletinsTable extends Component {
  static propTypes = {
    techBulletinsStore: mobxPropTypes.observableObject.isRequired,
    medicationStore: mobxPropTypes.observableObject.isRequired,
    loadingStore: mobxPropTypes.observableObject.isRequired,
    defaultLimit: PropTypes.number,
    showEmptyRows: PropTypes.bool,
    paginate: PropTypes.bool,
  };

  static defaultProps = {
    paginate: false,
    showEmptyRows: false,
    defaultLimit: 25,
  };

  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;

    this.state = {
      data: [],
      techBulletinsMeds: [],
      selectedMedIds: {},
      selectedTechBulletinMed: '',
      _limit: defaultLimit,
      filters: {},
      total: 0,
      page: 0,
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  static getTableHeader() {
    return [
      { id: 1, label: 'Name' },
      { id: 2, label: 'Formulation', hidden: { smDown: true } },
      { id: 3, label: 'Attachment' },
    ];
  }

  fetchData = async (searchFilters = null, reset = false) => {
    const { loadingStore, techBulletinsStore, medicationStore } = this.props;
    const { selectedMedIds } = this.state;
    loadingStore.setReady(false);

    const { _limit, page, filters } = this.state;
    if (searchFilters) {
      if (searchFilters.medications) {
        // eslint-disable-next-line no-param-reassign
        searchFilters.medications_in = selectedMedIds[searchFilters.medications];
      }
    }
    const newFilters = removeEmptyKeys(searchFilters || filters);
    const offset = _limit * page;
    const params = Object.assign(
      { _limit, _start: offset, _sort: 'name:ASC' },
      reset ? {} : newFilters,
    );

    try {
      const data = await techBulletinsStore.api.techBulletins.get({ params });
      const medications = await medicationStore.api.medications.get({ id: null });
      const techBulletinsMedsNames = ['BAM Kit', 'BAM', 'Buprenorphine', 'Meloxicam', 'MK', 'MK Kit'];
      const techBulletinsMeds = [];
      const techBulletinsMedNameIds = {};

      techBulletinsMedsNames.map((wantedMedName) => {
        techBulletinsMedNameIds[wantedMedName] = '';
        let medicationPushed = false;
        medications.results.map((medication) => {
          if (medication.name.indexOf(wantedMedName) > -1) {
            if (techBulletinsMedNameIds[wantedMedName] === '') {
              techBulletinsMedNameIds[wantedMedName] = String(medication.id);
            } else {
              techBulletinsMedNameIds[wantedMedName] = `${techBulletinsMedNameIds[wantedMedName]},${String(medication.id)}`;
            }
            if (!medicationPushed) {
              techBulletinsMeds.push(
                { id: medication.id, value: wantedMedName, label: wantedMedName },
              );
              medicationPushed = true;
            }
          }
          return medication;
        });
        return wantedMedName;
      });

      this.setState({
        data: data.results || [],
        techBulletinsMeds: techBulletinsMeds || [],
        selectedTechBulletinMed: '',
        selectedMedIds: techBulletinsMedNameIds,
        filters: reset ? {} : newFilters,
        total: data.totalCount || 0,
      });
    } catch (error) {
      this.setState({
        data: [],
        filters: newFilters,
      });
    }

    loadingStore.setReady(true);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.fetchData());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ _limit: event.target.value }, () => this.fetchData());
  };

  handleClear = (clearObj) => {
    this.setState(clearObj);
  };

  handleReset = async () => {
    this.setState({ filters: {} }, () => this.fetchData());
  };

  handleDownload = ({ attachment }) => {
    const token = storage.getItem('token');

    window.open(
      `${config.api.baseURL}/techbulletins${attachment.url}/?token=${token}/`,
      '_blank',
      'noopener',
    );
  };

  handleMedicationsAutoComplete = (input) => {
    if (!input) return [];

    const { medicationStore } = this.props;

    return medicationStore.fetchMedications(input);
  };

  renderRows = () => {
    const { data } = this.state;

    return data.map(row => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <TableCell component="th" scope="row" padding="default">
          {row.name}
        </TableCell>
        <Hidden smDown>
          <TableCell>{row.medications.map(item => item.name).join(', ')}</TableCell>
        </Hidden>
        <TableCell>
          <IconButton color="secondary" onClick={() => this.handleDownload(row)}>
            <DownloadIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  renderPagination = () => {
    const { total, _limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={_limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  };

  handleFormulationsValueChange = (input) => {
    const { selectedTechBulletinMed } = this.state;
    if (selectedTechBulletinMed !== '') {
      this.setState({ selectedTechBulletinMed: `${selectedTechBulletinMed},${input}` });
    } else {
      this.setState({ selectedTechBulletinMed: `${input}` });
    }
  }

  renderSearch = () => {
    const { techBulletinsMeds, selectedTechBulletinMed } = this.state;

    const fields = [
      {
        id: 'medications',
        name: 'medications',
        label: 'Formulation',
        type: 'select',
        validateAs: 'text',
        span: { xs: 0, md: 6, sm: 6 },
        defaultVal: `${selectedTechBulletinMed}`,
        placeholder: 'Please select a formulation',
        required: false,
        customProps: {
          inputProps: {
            name: 'medications',
            id: 'medications',
          },
          data: techBulletinsMeds,
        },
      },
    ];

    return (
      <SearchForm
        noButtonMargin
        onSubmit={this.fetchData}
        onReset={this.handleReset}
        fields={fields}
      />
    );
  };

  render() {
    const { data, _limit, page } = this.state;
    const { paginate, showEmptyRows } = this.props;

    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = _limit - Math.min(_limit, data.length - page * _limit);
    }

    return (
      <Fragment>
        <LicenseStatusBanner />
        <DeaStatusBaneer />
        {this.renderSearch()}
        <DataTable
          header={TechBulletinsTable.getTableHeader()}
          renderRows={this.renderRows}
          emptyRows={emptyRows}
          paginate={paginate}
          renderPagination={this.renderPagination}
          empty={data.length === 0}
        />
      </Fragment>
    );
  }
}

export default inject('techBulletinsStore', 'medicationStore', 'loadingStore')(
  observer(TechBulletinsTable),
);
