import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import MedicationsTable from '../../containers/MedicationsTable/MedicationsTable';

import DeaSeals from '../../assets/images/dea-seals.png';

const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
  img: {
    paddingTop: 20,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const MedicationsListPage = ({ classes, ...props }) => (
  <div className={classes.list}>
    <MedicationsTable {...props} paginate />
    <img src={DeaSeals} alt="dea-seals" className={classes.img} />
  </div>
);

MedicationsListPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(MedicationsListPage);
