import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Visibility } from '@material-ui/icons';
import {
  TablePagination, TableRow, IconButton, Hidden,
} from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import SearchForm from '../../components/SearchForm/SearchForm';
import DeaStatusBaneer from '../DeaStatusBanner/DeaStatusBanner';
import LicenseStatusBanner from '../LicenseStatusBanner/LicenseStatusBanner';

import { CATEGORIES_LIST } from '../../constants/animalCategories';

import removeEmptyKeys from '../../utils/removeEmptyKeys';

import DeaScheduleIcon1 from '../../assets/images/title-icon-1.png';
import DeaScheduleIcon2 from '../../assets/images/title-icon-2.png';
import DeaScheduleIcon3 from '../../assets/images/title-icon-3.png';
import DeaScheduleIcon4 from '../../assets/images/title-icon-4.png';

class PrescriptionsTable extends Component {
  static propTypes = {
    specieStore: mobxPropTypes.observableObject.isRequired,
    loadingStore: mobxPropTypes.observableObject.isRequired,
    medicationStore: mobxPropTypes.observableObject.isRequired,
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    defaultLimit: PropTypes.number,
    showEmptyRows: PropTypes.bool,
    paginate: PropTypes.bool,
  };

  static defaultProps = {
    paginate: false,
    showEmptyRows: false,
    defaultLimit: 25,
  };

  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;

    this.state = {
      data: [],
      _limit: defaultLimit,
      filters: {},
      total: 0,
      page: 0,
      clearSpecie: false,
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  static getTableHeader() {
    return [
      { id: 1, label: 'Formulation' },
      { id: 2, label: '' },
      { id: 3, label: 'Volume (conc.)', hidden: { smDown: true } },
      { id: 4, label: 'Description' },
      { id: 5, label: 'Drug Platform', hidden: { smDown: true } },
      { id: 6, label: '' },
    ];
  }

  fetchData = async (searchFilters = null, reset = false) => {
    const { loadingStore, medicationStore } = this.props;
    loadingStore.setReady(false);

    const { _limit, page, filters } = this.state;
    const newFilters = removeEmptyKeys(searchFilters || filters);

    const offset = _limit * page;
    const params = Object.assign(
      { _limit, _start: offset, _sort: 'name:ASC' },
      reset ? {} : newFilters,
    );

    try {
      const data = await medicationStore.api.medications.get({ id: null, params });

      this.setState({
        data: data.results || [],
        filters: reset ? {} : newFilters,
        total: data.totalCount || 0,
      });
    } catch (error) {
      this.setState({
        data: [],
        filters: newFilters,
      });
    }

    loadingStore.setReady(true);
  };

  handleSpeciesAutoComplete = async (input) => {
    if (!input) {
      return [];
    }
    const { specieStore } = this.props;
    return specieStore.fetchSpecies(input);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.fetchData());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ _limit: event.target.value }, () => this.fetchData());
  };

  handleViewClick = (row) => {
    const { history, match } = this.props;
    history.push(`${match.url}/view/${row.id}`);
  };

  handleClear = (clearObj) => {
    this.setState(clearObj);
  };

  handleReset = async () => {
    this.setState(
      {
        filters: {},
        clearSpecie: true,
      },
      () => this.fetchData(),
    );
  };

  renderRows = () => {
    const { data } = this.state;

    return data.map((row) => {
      let DeaScheduleIcon = null;

      switch (parseInt(row.deaSchedule, 10)) {
        case 1:
          DeaScheduleIcon = DeaScheduleIcon1;
          break;
        case 2:
          DeaScheduleIcon = DeaScheduleIcon2;
          break;
        case 3:
          DeaScheduleIcon = DeaScheduleIcon3;
          break;
        case 4:
          DeaScheduleIcon = DeaScheduleIcon4;
          break;
        default:
          DeaScheduleIcon = null;
          break;
      }

      return (
        <TableRow hover tabIndex={-1} key={row.id}>
          <TableCell component="th" scope="row" padding="default">
            {row.name}
          </TableCell>
          <TableCell>
            {DeaScheduleIcon && (
              <img src={DeaScheduleIcon} alt={`Dea Schedule: ${row.deaSchedule}`} />
            )}
          </TableCell>
          <Hidden smDown>
            <TableCell>{row.presentation}</TableCell>
          </Hidden>
          <TableCell>{row.productCategory}</TableCell>
          <Hidden smDown>
            <TableCell>{row.releaseCategory}</TableCell>
          </Hidden>
          <TableCell padding="none" isActions>
            <IconButton
              aria-label="View"
              color="secondary"
              onClick={() => this.handleViewClick(row)}
            >
              <Visibility />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  renderPagination = () => {
    const { total, _limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={_limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  };

  renderSearch = () => {
    const { clearSpecie } = this.state;

    const fields = [
      {
        id: 'name_contains',
        name: 'name_contains',
        label: 'Formulation',
        type: 'text',
        validateAs: 'string',
        defaultVal: '',
        required: false,
      },
      {
        id: 'animalcategories',
        name: 'animalcategories',
        label: 'Animal Category',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'text',
        defaultVal: '',
        required: false,
        customProps: {
          inputProps: {
            name: 'animalcategories',
            id: 'animalcategories',
          },
          data: CATEGORIES_LIST,
        },
      },
      {
        id: 'species',
        name: 'species',
        label: 'Species',
        type: 'autocomplete',
        validateAs: 'text',
        defaultVal: '',
        required: false,
        customProps: {
          defaultOptions: [],
          loadOptions: this.handleSpeciesAutoComplete,
          handleClear: this.handleClear,
          clearSpecie,
        },
      },
    ];

    return <SearchForm onSubmit={this.fetchData} onReset={this.handleReset} fields={fields} />;
  };

  render() {
    const { data, _limit, page } = this.state;
    const { paginate, showEmptyRows } = this.props;

    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = _limit - Math.min(_limit, data.length - page * _limit);
    }

    return (
      <Fragment>
        <LicenseStatusBanner />
        <DeaStatusBaneer />
        {this.renderSearch()}
        <DataTable
          header={PrescriptionsTable.getTableHeader()}
          renderRows={this.renderRows}
          emptyRows={emptyRows}
          paginate={paginate}
          renderPagination={this.renderPagination}
          empty={data.length === 0}
        />
      </Fragment>
    );
  }
}

export default inject('medicationStore', 'specieStore', 'loadingStore')(
  observer(PrescriptionsTable),
);
