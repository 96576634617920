import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TechBulletinsTable from '../../containers/TechBulletinsTable/TechBulletinsTable';

const styles = theme => ({
  list: { marginTop: theme.spacing.unit },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const TechBulletinsListPage = ({ classes, ...props }) => (
  <div className={classes.list}>
    <TechBulletinsTable {...props} paginate />
  </div>
);

TechBulletinsListPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(TechBulletinsListPage);
